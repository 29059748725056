.contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: unset;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: var(--border-radius);
    padding: 15px 22.5px;
    color: var(--color-primary-600);
}

.mogu-contact {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.primary-info {
    display: flex;
    flex-direction: column;
}

.contact-title {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28px);
    margin-bottom: 16px;
    margin-top: 2px;
    overflow-wrap: anywhere;
}

.logo-info {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    gap: 15px;
}

.product-logo {
    border-radius: 50%;
    height: 64px;
    width: 94px;
    position: relative;
    background-color: rgb(255, 255, 255);
}

.logo>img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: contain;
    max-width: 54px;
    max-height: 56px;
}

.info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.info>h4 {
    color: var(--color-primary-600) !important;
    font-size: 15px;
}

.aditional-info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.aditional-info>* {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    justify-content: start;
    align-items: center;
    gap: 15px;
}

.contact .aditional-info p {
    margin: 0 0 0 0;
}

.description {
    width: 100%;
}