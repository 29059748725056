.file-container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 20px;
  border-radius: var(--border-radius);
  background-color: var(--color-secondary-600);
  color: var(--color-primary-600);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 100%;
}

.file-box {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: 100%;
  min-width: 0px;
}

.remixicon-icon {
  width: 25px;
  height: 25px;
  color: rgb(51, 65, 85);
}

.file-url {
  font-size: 14px;
  line-height: calc(22px);
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: var(--color-primary-700);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-url-icon {
  flex-shrink: 0;
  cursor: pointer;
}
