/* ==========================================================================
   Elements > Page
   ========================================================================== */

body {
    color: color(text);
    background-color: color(body-bg);
    font-size: font(size);
    font-family: font(family, sans-serif);
    font-weight: font(weight, light);
    line-height: 1.2;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::selection {
  color: color(white);
  background: color(primary);
}
