.section {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: calc(22px);
    color: var(--color-primary-600);
    letter-spacing: 2px;
    text-transform: uppercase;
    overflow-wrap: anywhere;
}

h4 {
    margin-bottom: 0 !important;
}