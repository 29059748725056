.product-card {
    width: 281.75px;
    height: 400px;
    border-style: solid;
    margin: 0 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: 0.15s;
}
.product-link{
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
}
.product-card:hover{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 2px 4px 0px, rgba(60, 64, 67, 0.15) 0px 4px 12px 4px;
    cursor: pointer;
}

.content {
    height: 200px;
    overflow: hidden;
    padding: 0px 12px;
}

.content > h3 {
    color: var(--color-secondary-600);
    font-size: 20px;
    font-family: var(--primary-font);
}

.product-card p {
    font-size: 1em;
    color: var(--secondary-color);
}

.product-card .img-container {
    margin-bottom: 5px;
}

.product-card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 2.2;
}

.duration {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 10px;
    height: 11px;
    text-wrap: nowrap;
}

.duration p {
    font-size: 0.8em;
    padding: 0 3px;
    color: var(--color-primary-600);
    font-weight: 600;
}


.proposal-price {
    position: absolute;
    right: 12px;
    bottom: 10px;
    color: var(--color-secondary-500);
    z-index: 2;
}

.proposal-price .from {
    text-align: right;
    font-size: 14px;
    margin-bottom: 2px;
}

.proposal-price .to {
    font-size: 20px;
    font-weight: 600;
}

.description {
    display: -webkit-box;
    overflow: hidden;
    text-wrap: wrap;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-height: 138px;
}

.product-card .show-more{
    position: absolute;
    font-size: 0.8em;
    bottom: 15px;
    font-weight: 600;
    color: var(--color-primary-600);
    padding: 0px 12px;
}