.i-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(15,23,42,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .24s;

    
    ::-webkit-scrollbar {
        height: 10px;
        width: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #cbd5e1;
        border: none;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f5f9;
    }
}

.modal-button {
    font-family: inherit;
    display: inline-block;
    text-decoration: none;
    border: none;
    border-radius: 6px !important;
    cursor: pointer;
    transition: opacity .25s ease-in-out;
    opacity: 1;
    min-height: 40px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .4px;
    padding: 10px 16px;
    background-color: #f1f5f9;
    color: #152b5b;
}

.i-modal-container.visible.centered .i-modal {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.i-modal-container.centered .i-modal {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
}

.i-modal-header {
    justify-content: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid rgba(14,36,58,.04);
}

.i-modal-footer, .i-modal-header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 48px;
    background-color: #f6f8fa;
}

.i-modal-body {
    position: relative;
    max-height: 70vh;
    overflow-y: auto;
}

.i-modal-footer {
    grid-gap: 16px;
    justify-content: flex-end;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 8px 16px;
    border-top: 1px solid rgba(14,36,58,.04);
}

.i-modal-container .i-modal {
    position: relative;
    background-color: #fff;
    width: 95vw;
    max-height: 90vh;
    border-radius: 6px;
    box-shadow: 0 7px 29px 0 rgba(100,100,111,.2);
    transition: opacity .24s,-webkit-transform .24s;
    transition: transform .24s,opacity .24s;
    transition: transform .24s,opacity .24s,-webkit-transform .24s;
    opacity: 0;
}

.i-modal-header .i-modal-close-button {
    position: absolute;
    left: 1em;
    padding: 0;
}

.modal-button .button__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
}

.modal-button.transparent {
    background-color: transparent;
    color: inherit;
    border-color: transparent;
}

.modal-button .remixicon-icon {
    width: 20px;
    height: 20px;
}

.i-modal-header .content {
    font-family: "Inter",sans-serif;
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
    color: #152b5b;
}

.i-modal-body .i-modal-body-inner {
    position: relative;
    padding: 16px;
}

.modal-button.primary {
    background-color: #195bf5;
    color: #fff;
}

.i-modal-body-inner-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (min-width: 768px) {
    .i-modal-container .i-modal {
        width: 700px;
    }
}
