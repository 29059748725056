/* ==========================================================================
   Elements > Code
   ========================================================================== */

pre,
code {
    font-family: font(family, monospace);
    background-color: color(gray);
}

pre {
    overflow: auto;
    padding: double(space(base-4));
    font-size: font(size, medium);
    line-height: font(height, medium);
    white-space: pre-wrap;
    word-wrap: break-word;
}

p code {
    padding: half(quarter($line-height-medium)) quarter($line-height-medium);
}