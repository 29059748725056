@media (min-width: 768px)
{
    .flight-card {
        flex-direction: row;
        border-radius: 6px;
        border: 1px solid #f8fafc;
    }
}

@media (max-width: 768px)
{
    .flight-card {
        flex-direction: column;
    }
}

.flight-card {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: var(--color-secondary-300);
    color: var(--color-primary-500);
}

@media (min-width: 768px) {
    .flight-card__header {
        flex-direction: column;
        padding: 0.5em;
    }
}

@media (max-width: 768px) {
    .flight-card__header {
        display: flex;
        padding: 0.5em;
    }
}

@media (min-width: 768px) {
    .flight-card__header {
        display: flex;
        border-right: 2px solid #e2e8f0;
        width: 30%;
    }
}

.flight-card__header {
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .flight-card__header-main {
        flex-direction: column;
    }
}

.flight-card__header-main {
    grid-gap: 0.8rem;
    display: flex;
    flex: 1 1;
    align-items: center;
}

.flight-card__header-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
}

.flight-card__header-carrier {
    display: flex;
    flex-direction: column;
}

.flight-card__header-carrier-name {
    font-family: "Inter",sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

@media (min-width: 768px)
{
    .flight-card__header-carrier-name {
        line-height: 2.4rem;
    }
}

@media (max-width: 768px)
{
    .flight-card__header-carrier-name {
        line-height: 1.8rem;
    }
}


.flight-card__header-actions-code {
    font-size: 1rem;
    line-height: 2.2rem;
    font-family: "Inter",sans-serif;
    font-weight: 600;
    padding: 0.2rem 0.8rem;
    border-radius: 6px;
    background-color: var(--color-secondary-100);
    border: 1px solid #e2e8f0;
}

.flight-card__content {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    padding: 0 0.4rem;
}

.flight-card__content-card {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 0.4rem;
    border-radius: 6px;
    padding: 0.8rem;
    
}

.destination {
    align-items: flex-end;
    text-align: end;
}

.flight-card__content-card-airport {
    color: var(--color-primary-400);
    font-size: 0.8rem;
}

.flight-card__content-card-airport-iata {
    font-family: "Inter",sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
}

.flight-card__content-card-date {
    font-size: 0.8rem;
    white-space: nowrap;
    color: var(--color-primary-400);
}

.flight-card__content-card-time {
    font-size: 1.2em;
    color: var(--color-primary-400);
}

.flight-card__content-middle {
    display: flex;
    flex-direction: column;
    grid-gap: 0.8rem;
    align-items: center;
    justify-content: center;
    
}

.ri-arrow-right-line {
    font-size: 25px;
}