.itineraries-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    height: unset;
}

.itinerary-title-container:hover {
    cursor: pointer;
}

.itineraries-box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.itinerary-element {
    border-radius: var(--border-radius);
    padding: 36px 24px;
    border-left: 4px solid var(--color-primary-700);
}

.itinerary-title-container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background-color: unset;
    border: none;
}

.itinerary-title {
    font-size: 18px;
    line-height: calc(26px);
    font-family: Inter, sans-serif;
    font-weight: 500;
    flex: 1 1 0%;
    max-width: 300px;
    color: var(--color-primary-700);
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
}

.itinerary-title-icon {
    transition: transform 0.24s ease 0s;
}

.itinerary-title-icon-expanded {
    transition: transform 0.24s ease 0s;
    transform: rotate(90deg);
}

.itinerary-images-container {
    display: block;
    margin-top: 1.2rem;
    width: 100%;
    height: unset;
}

.events-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 30px;
    color: var(--color-primary-700);
}

.itinerary-body {
    margin-top: 8px;
}

@media (min-width: 768px) {
    .itineraries-box {
        box-shadow: none;
        border-radius: 0px;
    }    

    .itinerary-element {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        padding: 32px;
        margin-bottom: 30px;
    }

    .itinerary-title-container {
        border-top: none;
    }

    .itinerary-title {
        max-width: initial;
        white-space: inherit;
        overflow: inherit;
        text-overflow: inherit;
    }
}