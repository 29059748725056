.header-basics-container {
  width: 100%;
  height: unset;
  margin-top: 10px;
  padding: 0px 30px;
  z-index: 10;
  position: relative;
}

.header-basics-grid {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: transparent;
  padding: 28px 5px;
  border-radius: var(--border-radius);
}

.header-basics-img {
  width: 100px;
  height: auto;
}

.header-basics-title-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-basics-title {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: calc(32px);
  color: var(--color-secondary-600);
  margin-bottom: 8px;
  width: 100%;
  word-break: normal;
  overflow-wrap: anywhere;
}

.header-basics-subtitle {
  color: var(--color-primary-700);
}

.header-basics-box {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  column-gap: 5px;
}

@media (min-width: 768px) {
  .header-basics-grid {
    margin-left: 0px;
    margin-right: 0px;
    flex-direction: row;
  }

  .header-basics-img {
    width: 100px;
    height: unset;
  }
}
