.price {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
  height: unset;
}

.price-box {
  color: var(--color-primary-700);
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 100%;
  height: unset;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 24px;
}

.button {
  font-family: inherit;
  text-decoration: none;
  line-height: 1;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
  font-size: 1rem;
  letter-spacing: 0.4px;
  background-color: rgb(21, 10, 68);
  color: white;
}

.prices-block {
  width: 100%;
}

.price-title {
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1 1 0%;
  padding-top: 8px;
}

@media (min-width: 768px) {
  .main-price-row {
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .main-price-row {
    flex-direction: column;
  }
}

.main-price-row {
  display: flex;
  gap: 16px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: calc(26px);

}

.description {
  font-size: 14px;
  line-height: calc(22px);
  margin-top: 16px;
}

.price-button {
    background-color: var(--color-secondary-500) !important;
    color: var(--color-secondary-100) !important; 
}

.price-with-currency {
  color: var(--color-secondary-500);
}
