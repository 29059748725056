.video-container {
  display: flex;
  width: 100%;
  height: fit-content;
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 30px;
}

.video-container iframe {
  width: 100%;
  height: fit-content;
  background-color: rgb(241, 245, 249);
  aspect-ratio: 4 / 3;
}
