.stripe__form-question {
  margin-bottom: 20px;

  label {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin-bottom: 4px;
    padding-left: 2px;
    font-size: 14px;
    line-height: 22px;
  }
}

.form-question__required-indicator {
  color: #e2352c;
}

.mogu-input {
  position: relative;
  flex-direction: column;
  display: flex;
}

.mogu-input .mogu-input-label {
  display: flex;
  align-items: center;
  grid-gap: 6px;
}

.mogu-input .mogu-input__container {
  position: relative;
}

.mogu-input input {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  border: 1px solid rgba(21, 43, 91, 0.08);
  background-color: #fff;
  color: #152b5b;
  font-size: 15px;
  min-height: 40px;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  transition: border-color 0.24s, background-color 0.24s;
}

.mogu-textarea {
  position: relative;
  display: flex;
  flex-direction: column;
}

.mogu-textarea-label {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 5px;

  
}

.question-label {
  font-family: "Inter",sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 4px !important;
  padding-left: 2px !important;
  font-size: 14px !important;
  line-height: 22px;
  color: #152b5b;
}

textarea {
  resize: vertical;
  height: 110px;
  font-family: "Inter",sans-serif;
  font-weight: 400;
  border: 1px solid rgba(21,43,91,.08);
  background-color: #fff;
  color: #152b5b;
  font-size: 1.5rem;
  min-height: 4rem;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  transition: border-color .24s,background-color .24s;
}

.chars-counter {
  margin-top: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #84929e;
  text-align: right;
}

.mogu-textarea textarea:focus {
  outline: none;
  border-color: rgba(21,43,91,.4);
  background-color: #f6f7f9;
  transition: border-color .24s,background-color .24s;
}

.mogu-input input:hover {
  box-shadow: none !important;
  border-color:rgba(21, 43, 91, 0.08) !important;
}

.mogu-input input:focus {
  outline: none;
  border-color: rgba(21,43,91,.4) !important;
  background-color: #f6f7f9;
  transition: border-color .24s,background-color .24s;
  box-shadow: none !important;
}

.stripe__form-question-label {
  display: flex;
  grid-gap: 2px;
}

.stripe__form-question-choice {
  display: flex;
  grid-gap: 16px;
  margin-top: 8px;
}

.stripe__form-question-choice__label {
  font-size: 14px;
  line-height: 22px;
  color: #152b5b;
}

.mogu-checkbox .inner {
  display: flex;
}

.mogu-checkbox.question-choice__input-radio .inner input, .mogu-radio.question-choice__input-radio .inner input {
  margin-top: 0;
  margin-left: 2px;
}

.mogu-checkbox input[type=checkbox] {
  width: 16px;
  height: 16px;
  margin: 4px 8px 0 0;
}