.not-included-services {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 100%;
    height: unset;
    border-radius: var(--border-radius);
    padding: 15px 22.5px;
    color: var(--color-primary-600);
}

.not-included-services-title {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28px);
    margin-bottom: 16px;
    margin-top: 2px;
    overflow-wrap: anywhere;
    color: rgb(236, 1, 1);
}