.product-sidebar {
  .section-name {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: calc(24px);
    overflow-wrap: anywhere;
    color: var(--color-primary-700)
  }
  .info-box {
    display: none;
  }
  .nav-container {
    margin-top: 0px;
    overflow-y: auto;
    width: 100%;
    padding-right: 10px;
    height: 100%;
  }
  .menu-title {
    text-transform: uppercase;
    width: clamp(140px, 200px, 200px);
    height: unset;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: calc(26px);
    color: var(--color-primary-700);
    margin-bottom: 12px;
    overflow-wrap: anywhere;
  }
  .code {
    font-size: 16px;
    line-height: calc(24px);
    color: var(--color-primary-700);
    margin-bottom: 20px;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
  }
  .section-link {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 12px;
    color: rgb(21, 10, 68);
    cursor: pointer;
    background-color: unset;
    border: none;
    .arrow {
      flex-shrink: 0;
    }
  }

  .pdf-button {
    box-shadow: inset 0 0 0 1px #000;
    background-color: transparent;
    color: inherit;
  }
  .button {
    font-family: inherit;
    display: inline-block;
    text-decoration: none;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
    min-height: 40px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    padding: 10px 16px;
    background-color: var(--color-secondary-600);
    color: var(--color-primary-700);
  }

  @media (min-width: 992px) {
    .button {
      max-width: 275px;
    }
    .button:hover {
      cursor: pointer;
      transition: opacity 0.25s ease-in-out;
      opacity: 0.9;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .button__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
  }
  .button-divider {
    width: 100%;
    height: 1px;
    background-color: rgb(0, 0, 0);
  }
}

@media (min-width: 992px) {
  .product-sidebar {
    display: block;
    .info-box {
      display: block;
    }
    .nav-container {
      margin-top: 20px;
    }
    .actions {
      row-gap: 15px;
    }
  }
}

.mogu__checkout-button {
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: rgb(255, 255, 255);
  margin-bottom: 0px;
  overflow-wrap: break-word;
  box-shadow: inset 0 0 0 1px #000;
}
