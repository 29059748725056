.activities {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    width: 100%;
    height: unset; 
}

@media (min-width: 768px) {
    .activities-grid {
        flex-direction: column;
        column-gap: 2.5rem;
    }
}
@media (max-width: 768px) {
    .activities-grid {
        flex-direction: column;
        row-gap: 2.5rem;
    }
}
.activity {
    row-gap: 1rem;
    border-radius: var(--border-radius);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    padding: 1.5rem;
    display: flex;
    color: var(--color-primary-700);
}  
.activities-grid {
    background-color: rgb(255, 255, 255);
    border-radius: var(--border-radius);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    padding: 2.4rem;
    display: flex;
    width: 100%;
}
@media (min-width: 768px) {
    .activity {
        flex-direction: row;
        column-gap: 1.5rem;
    }
}
@media (max-width: 768px) {
    .activity {
        flex-direction: column;
    }
}

@media (min-width: 576px) {
    .activity-image {
        width: 12rem;
        height: 8rem;
    }
}
@media (max-width: 576px) {
    .activity-image {
        width: 100%;
        height: 14rem;
    }
}
.activity-image {
    flex-shrink: 0;
    border-radius: var(--border-radius);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.info {
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;    
}
.price-tag {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 1rem;
    gap: 1rem;
}

@media (max-width: 576px) {
    .title-with-price {
        flex-direction: column;
    }
}
@media (min-width: 576px) {
    .title-with-price {
        flex-direction: row;
        gap: 1.6rem;
    }
}
.title-with-price {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex: 1 1 0%;
    font-size: 1.8rem;
    line-height: calc(2.6rem);
    font-family: Inter, sans-serif;
    font-weight: 500;
}

@media (min-width: 576px) {
    .activity-price {
        align-self: end;
    }
}
.activity-price {
    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    color: var(--color-secondary-500);
}
.activity-title {
    font-size: 20px;
    font-weight: 600;
}
.price-tag .tag {
    border-radius: var(--border-radius);
    background-color: rgba(192, 29, 68, 0.04);
    padding: 0.2rem 0.8rem;
    font-size: 1rem;
}