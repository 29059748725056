.event-base {
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 100%;
    height: unset;
    border-radius: var(--border-radius);
    padding: 22.5px;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    color: var(--color-primary-700);
}

.event-info {
    margin-top: 16px;
}

.event-title {
    color: var(--color-primary-700);
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: Inter,sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: calc(24px);
    margin-bottom: 8px;
}
.place {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: calc(28px);
    margin-bottom: 6px;
}

.place-information-container {
    display: flex;
    align-items: center;
    grid-gap: 0.6rem;
    margin-bottom: 0.8rem;
    max-width: 100%;
}

.place-rating {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
}

.place-rating-number {
    font-size: 1.2rem;
    line-height: 2.2rem;
    margin-right: 0.6rem;
    color: #64748b;
}

.place-rating-stars {
    display: flex;
    grid-gap: 0.6rem;
}

.place-rating-star {
    color: #ffd11a;
}

.room-info {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 2rem;
    color: rgb(71, 85, 105);
    width: 100%;
    margin-left: 0.8rem;
}

.roomType {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 65, 85);
    line-height: calc(2rem);
    font-family: Inter, sans-serif;
    font-weight: 500;

}

.regime {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 65, 85);
    line-height: calc(2rem);
    font-family: Inter, sans-serif;
    font-weight: 500;

}

.nights {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(51, 65, 85);
    line-height: calc(2rem);
    font-family: Inter, sans-serif;
    font-weight: 500;

}

.ship-name {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin: 2rem 0px;
}

.ship-name-container {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.2rem;
}

.google-widget-rating-container {
    display: flex;
    grid-gap: 1rem;
}
.google-widget-rating-container a {
    color: rgb(71, 85, 105);
}
