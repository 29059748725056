#banner {
    position: relative;
    height: 488px;
    display: grid;
    grid-template-columns: 39vw 60vw;
    background-color: var(--color-primary-700);
}

div#banner > * {
    width: 100%;
}

.product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-left: 6vw;
    line-height: 2.2em;
    opacity: 0.85;
    justify-self: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 28vw !important;
}

.product-info>.banner-info {
    flex-basis: 65%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 10px;
}

.banner-info>h3 {
    color: white;
    font-size: 22px;
}

.banner-info>h2 {
    line-height: 30px;
    color: white;
    font-size: 22px;
    margin-bottom: 0;
}

.banner-info>h4 {
    color: white;
    font-size: 15px;
}

.product-info>.banner-info>.days {
    color: white;
    font-size: 20px;
    opacity: 0.8;
}

.product-info>.banner-info>.banner-title {
    font-size: 1.9em;
    color: white;
    padding-bottom: 5px;
    line-height: 46px;
}

.product-info>.banner-info>.price-info {
    padding-top: 30px;
    font-size: 1.5em;
    font-weight: 700;
    color: white;
}

.price-info>h3 {
    color: white;
    font-size: 22px;
}